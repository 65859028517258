<template>
  <!-- <nav class="bg-customNavbar flex justify-between items-center px-4 py-2"> -->
  <nav class="bg-customNavbar flex justify-between items-center px-4 py-0 sm:px-4 sm:py-2" @click.self= "emitToggleNavbar">
 
    <!-- Placeholder for Menu for Guest Facilitator -->
    <div v-if="this.currentRole && this.currentRole == 'Guest Facilitator'" @click="handleHamburgerIconClick" class="flex sm:hidden items-center mr-2 hs-tooltip inline-block">
      <svg class="hs-tooltip-toggle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    
    <!-- Hambuger Menu Icon for Admin, Educator-->
    <div v-if="this.currentRole == 'Educator' || this.currentRole == 'System Manager'" class="flex sm:hidden items-center mr-2 hs-tooltip inline-block " aria-haspopup="dialog" aria-expanded="true" aria-controls="hs-offcanvas-example-admin" aria-label="Toggle navigation" data-hs-overlay="#hs-offcanvas-example-admin">
      <svg class="hs-tooltip-toggle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>

    <!-- Hambuger Menu Icon for Parent-->
    <div v-if="this.currentRole== 'Parent'" class="flex sm:hidden items-center mr-2 hs-tooltip inline-block " aria-haspopup="dialog" aria-expanded="true" aria-controls="hs-offcanvas-example-parent" aria-label="Toggle navigation" data-hs-overlay="#hs-offcanvas-example-parent">
      <svg class="hs-tooltip-toggle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
   
    <!-- App Logo for desktop -->
    <div class="hidden md:flex items-center ml-5 mt-2.5 md:mt-0"> <!-- Added left margin -->
     <router-link to="/front-portal">
      <img 
        src="@/assets/app-logos/zippielogo.png" 
        alt="Currie Navbar Logo" 
        class="w-[75.25px] h-[32px] md:w-[99px] md:h-[42px]">
     </router-link>
    </div>

    <!-- Z Logo for mobile screens  -->
    <div class="md:hidden flex items-center ml-3">
     <router-link to="/front-portal">
      <img 
        src="@/assets/app-logos/zippie-new-z-logo.png" 
        alt="app logo" 
        class="w-[24px] h-[24px]">
     </router-link>
    </div>
    
    <!-- Group on the Right Side of screen or navbar -->
    <div class="ml-auto flex justify-end items-center h-[50px] w-[150px] sm:h-[60px] sm:w-[200px] print:hidden"> <!-- Specific pixel values -->
    
      <!-- Left part of Icons/ Left Group-->
      <div class="flex items-stretch md:border-r border-black pr-2 py-1 md:gap-3"> <!-- Adjust height here-->   
        
        <!-- Expand app for full screen icon -->
        <!-- <button v-if="isFullScreen" @click="goFullScreen" class="md:hidden">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M2.85156 16.125H8.10156V21.375M16.3516 21.375V16.125H21.6016M21.6016 7.875H16.3516V2.625M8.10156 2.625V7.875H2.85156" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button> -->
        
        <!-- Shrink app from full screen icon -->
        <!-- <button v-else @click="goFullScreen" class="md:hidden">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.09448 5V3C1.09448 2.46957 1.3052 1.96086 1.68027 1.58579C2.05534 1.21071 2.56405 1 3.09448 1H5.09448M15.0945 1H17.0945C17.6249 1 18.1336 1.21071 18.5087 1.58579C18.8838 1.96086 19.0945 2.46957 19.0945 3V5M19.0945 15V17C19.0945 17.5304 18.8838 18.0391 18.5087 18.4142C18.1336 18.7893 17.6249 19 17.0945 19H15.0945M5.09448 19H3.09448C2.56405 19 2.05534 18.7893 1.68027 18.4142C1.3052 18.0391 1.09448 17.5304 1.09448 17V15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.0945 6H6.09448C5.5422 6 5.09448 6.44772 5.09448 7V13C5.09448 13.5523 5.5422 14 6.09448 14H14.0945C14.6468 14 15.0945 13.5523 15.0945 13V7C15.0945 6.44772 14.6468 6 14.0945 6Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button> -->

        <!-- Shrink app from full screen icon after navbar is hidden -->
        <!-- <button v-if="" @click="goFullScreen" class="fixed top-[6%] right-[8px] mt-0.5 z-10">
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_5322_35397)">
            <rect x="2.77344" y="2.60938" width="24" height="24" rx="4" fill="white"/>
            <path d="M5.39844 18.7344H10.6484V23.9844M18.8984 23.9844V18.7344H24.1484M24.1484 10.4844H18.8984V5.23438M10.6484 5.23438V10.4844H5.39844" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <filter id="filter0_d_5322_35397" x="0.773438" y="0.609375" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5322_35397"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5322_35397" result="shape"/>
            </filter>
            </defs>
          </svg>
        </button>
        <button v-else @click="goFullScreen" class="fixed top-[6%] right-[8px] mt-0.5 z-10">
        </button> -->
        

        <!-- Search Icon -->
        <!-- <div class="hs-tooltip inline-block [--trigger:click] [--placement:right]">
           <router-link to="" class="hidden mx-2 hs-tooltip-toggle" ><SearchIcon /></router-link>
           <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
            Search coming soon! 
           </span>
        </div> -->

        <!-- Message Icon -->
        <div v-if="userRoleView && userRoleView =='Parent'" class="">          
          <router-link to="/front-portal/parent-messages" class=""><MessagesIcon /></router-link>
        </div>  

        <!-- Notification Icon -->
        <!-- <div class="hs-tooltip flex [--trigger:click] [--placement:right]">
          <router-link to="" class="mx-2 hs-tooltip-toggle"><NotificationsIcon /></router-link>
          <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
            Notifications coming soon! 
          </span>
        </div>   -->
        <!-- <div class="w-8 flex justify-center items-center cursor-pointer">
          <notificationicon :notifications="2" />
        </div> -->
        <Notifications/>
      </div>
      
      <!-- Right part of Icons /Right Group-->
      <div class="md:pl-4">
		     <!-- <button 
            v-if="$auth.isLoggedIn" 
            @click="$auth.logout()"
            class="py-1 px-3 text-sm md:py-1.5 md:px-1 md:text-base ml-2 md:ml-1 bg-customNavbar border-black" >
          logout
        </button> -->
        <ProfileDropdown v-if="currentRole" @roleChanged="handleRoleChange" @show-install-zippie-modal="onShowInstallZippieModal" :current-role="this.currentRole" class="z-50"/>
	    </div> 
      <!-- <router-link to="/profile" class="mx-2"><ProfileIcon /></router-link>
      <img src="" alt="Pic" class="ml-2">
     -->
      <!-- Company Logo (Image or inline SVG) -->
      <!-- <img src="@/assets/company-logo.svg" alt="Company Logo" class="hidden sm:flex ml-2"> -->
    
    </div>
  </nav>
</template>



<script>
import SearchIcon from './icons/SearchIcon.vue';
import MessagesIcon from './icons/MessagesIcon.vue';
// import NotificationsIcon from './icons/NotificationsIcon.vue'
import ProfileDropdown from './ProfileDropdown.vue';
import NotificationIcon from './icons/NotificationIcon.vue';
import Notifications from './notifications/Notifications.vue';

export default {
  components: {
    SearchIcon,
    MessagesIcon,
    NotificationIcon,
    Notifications,
    ProfileDropdown
  },
  props: {
      userDetails: {
        type: Object,
        required: true,
      },
      currentRole: {
      type: String,
      required: true,
    },
    userRoleView: {
      type: String,
      required: true,
    }
    },
  inject: ['$auth'],
  data() {
    return {
    isFullScreen: false,
    }
  },

  mounted(){
    setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside Navbar after 100ms delay");
    }, 1000)
    setTimeout(() => {
      if (window.innerWidth < 768){
        this.$emit('toggle-navbar')
      }
    }, 5000);
  },
  computed: {
    isOnlyGuestFacilitator() {
      const roles = this.userDetails.user_roles;
      return roles.length === 1 && roles[0] === 'Guest Facilitator';
    }
  },
  methods: {
    handleHamburgerIconClick() {
      //emit event to display guest facilitator restriction modal
        this.$emit('show-modal'); 
    },
    emitToggleNavbar(){
      if (window.innerWidth < 768){
        this.$emit('toggle-navbar')
      }
    },
    handleRoleChange(){
      this.$emit('show-role-switch-confirmation-modal')
      setTimeout(() => {
        window.HSStaticMethods.autoInit();
        console.log("thiss is preline initialised after fetching")
      }, 100) 
    },
    onShowInstallZippieModal(){
      this.$emit('show-install-zippie-modal')
    },
    // Disable beause of PWA(Progressinve web app, can not allow to go full screen on that)
    // goFullScreen(){
    //   if (window.innerWidth < 768){
    //     if (!document.fullscreenElement) {
    //     if (document.documentElement.requestFullscreen) {
    //       document.documentElement.requestFullscreen();
    //     } else if (document.documentElement.mozRequestFullScreen) { // Firefox
    //       document.documentElement.mozRequestFullScreen();
    //     } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
    //       document.documentElement.webkitRequestFullscreen();
    //     } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
    //       document.documentElement.msRequestFullscreen();
    //     }
    //     this.isFullScreen = true;
    //   } else {
    //     if (document.exitFullscreen) {
    //       document.exitFullscreen();
    //     } else if (document.mozCancelFullScreen) { // Firefox
    //       document.mozCancelFullScreen();
    //     } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
    //       document.webkitExitFullscreen();
    //     } else if (document.msExitFullscreen) { // IE/Edge
    //       document.msExitFullscreen();
    //     }
    //     this.isFullScreen = false
    //   }
    //   }
    // }
  },
};
</script>

