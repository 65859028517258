import { computed, ref } from "vue";

export function useNotifications() {
  // state
  const readNotifications = ref([])           // Notifications(id) that have been read

  const rtNotificationQueue = ref([])         // A queue for adding new notifications
  const newNotificationsPresent = ref(true)

  const fetchedNotifications = ref([])        // notifications fetched from the server initially

  // List of all the notifications
  const notificationList = computed(() => {
    const notifications = [...rtNotificationQueue.value,...fetchedNotifications.value];
    readNotifications.value.forEach((notiName) => {
      const notiIndex = notifications.findIndex((noti) => noti.name === notiName);
      if (notiIndex === -1) return;
      notifications[notiIndex].status = 'Read'
    })
    // return notificationResource.data.notifications;
    return notifications;
  })

  const unreadCount = computed(() => {
    return notificationList.value.filter((notification) => notification.status === 'Unread').length
  })

  const setFetchedNotifications = (notificationList) => {
    fetchedNotifications.value = notificationList;
  }

  const addToQueue = (sampleNoti) => {
    // console.log("Adding new notification")
    // console.log('notificationQue', rtNotificationQueue.value)
    const newIndex = rtNotificationQueue.value.length;
    // console.log("new index", newIndex)
    rtNotificationQueue.value.push({
      ...sampleNoti,
      actor_name: `${sampleNoti.actor_name}-${newIndex}`,
      active: true
    })

    newNotificationsPresent.value = true;

    setTimeout(() => {
      rtNotificationQueue.value[newIndex].active = false;
    }, 5000)
  }

  const makeInactive = (queueIndex) => {
    rtNotificationQueue.value[queueIndex].active = false;
  }

  const addToReadList = (notificationId) => {
    readNotifications.value.push(notificationId)
  }

  const removeFromReadList = (notificationId) => {
    readNotifications.value = readNotifications.value.filter((noti) => noti.name !== notificationId)
  }

  return {
    readNotifications, rtNotificationQueue, newNotificationsPresent,
    notificationList, unreadCount, setFetchedNotifications,
    addToQueue, makeInactive, addToReadList, 
    removeFromReadList
  }
}