<template>
  <div class="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-neutral-800 
    dark:border-neutral-700 relative" v-if="props.notification?.active"
    role="alert" tabindex="-1" aria-labelledby="hs-toast-avatar-label">
    <div class="flex p-4">
      <div class="shrink-0">
        <p v-if="props.notification.actor_name" class="size-8 bg-[#FF8B70] text-white 
          flex items-center justify-center rounded-full shrink-0">
          <!-- Replace with user avator later-->
          {{ props.notification.actor_name[0] }}
        </p>
        <!-- <img class="inline-block size-8 rounded-full" 
          src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80" alt="Avatar"> -->
        <button type="button" class="absolute top-3 end-3 inline-flex shrink-0 justify-center items-center 
          size-5 rounded-lg text-gray-800 opacity-50 hover:opacity-100 focus:outline-none 
          focus:opacity-100 dark:text-white" aria-label="Close" @click="() => props.closeFn(props.queueIndex)">
          <span class="sr-only">Close</span>
          <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="ms-4 me-5">
        <h3 id="hs-toast-avatar-label" class="text-gray-800 font-medium text-sm dark:text-white">
          <span v-if="props.notification?.type === 'Admin Message'" class="font-semibold">
            New Admin message!
          </span>
          <span v-else>
            <span class="font-semibold" v-if="props.notification?.actor_name">
              {{ props.notification.actor_name }}
            </span>&nbsp;
            <span>{{ description }}</span>
          </span>
        </h3>
        <div v-if="props.notification.message"
          class="mt-1 text-sm text-gray-600 dark:text-neutral-400 line-clamp-2">
          {{ props.notification.message }}
        </div>
        <div class="mt-3 space-x-4">
          <router-link :to="`/front-portal/observations/view/${getObservType(props.notification.source_type)}/all?observ=${props.notification.source_id}&scroll=1`"
            v-if="(props.notification.type === 'Observation Comment' || props.notification.type === 'Observation Comment Tag')
              && !!props.notification.source_id"
            >
            <button type="button" class="text-blue-600 decoration-2 hover:underline font-medium text-sm
              focus:outline-none focus:underline dark:text-blue-500" @click="markAsRead"
              >
              View
            </button>
          </router-link>
          <button type="button" class="text-blue-600 decoration-2 hover:underline font-medium text-sm 
            focus:outline-none focus:underline dark:text-blue-500" @click="markAsRead">
            Mark as read
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps([
  'notification', 'queueIndex', 'closeFn', 
  'markAsReadFn'
])

const description = computed(() => {
  if (!props.notification) return ''
  if (props.notification.type === 'Observation Comment')
    return `commented on an observation you posted.`
  if (props.notification.type === 'Observation Comment Tag')
    return `tagged you in a comment.`
  return 'Random Test Notification'
})

const markAsRead = () => {
  props.closeFn(props.queueIndex)
}

const getObservType = (doctype) => {
  const baseDoctype = doctype.split(' ').slice(0, 2).join(' ')
  if (baseDoctype === 'Daily Observations') return 'child'
  if (baseDoctype === 'Activity Observations') return 'activity'
  if (baseDoctype === 'Unstructured Feedback') return 'unstructured'
}
</script>