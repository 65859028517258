<template>


  <!-- Mobile sidebar menu for Admin and Educator -->
  <phoneMenu v-if="this.userDetails && (this.roleChangedTo == 'Educator'|| this.roleChangedTo == 'System Manager')" :user-details="this.userDetails" :current-role="this.roleChangedTo" @show-role-switch-confirmation-modal= "showRoleSwitchConfirmationModal=true"></phoneMenu>

  <!-- Mobile sidebar menu for Parent  -->
  <guestFacilitatorMenu v-if="this.userDetails && this.roleChangedTo == 'Parent'" :user-details="this.userDetails" :current-role="this.roleChangedTo" @show-role-switch-confirmation-modal= "showRoleSwitchConfirmationModal=true"></guestFacilitatorMenu>
 
  
  <div class="container-div h-screen overflow-hidden md:overflow- print:h-max">
    <!-- <div>
		<button v-if="$auth.isLoggedIn" @click="$auth.logout()">Logout</button>
		
	</div> -->
    <div class="wrapper print:h-max" :class="['transition-all duration-400 linear',
      navbarHidden ? 'transform -translate-y-[38px]' : '']">
      <!-- Navbar -->

      <Navbar v-if="$auth.isLoggedIn && this.userDetails && termsAndPrivacyAccepted" :user-details="this.userDetails" :current-role="this.roleChangedTo" :user-role-view="this.roleChangedTo"
      @toggle-navbar="toggleNavbar" @show-role-switch-confirmation-modal= "showRoleSwitchConfirmationModal=true" @show-modal="showGuestNavigationErrorModal = true"
      @show-install-zippie-modal ="showInstallZippieModal = true"
        class=" h-[6%] md:h-[8.5%] md:sticky top-0 w-full z-50" />


      <div class="h-[100%] md:h-auto flex print:h-max" style="height: 100%;">

        <!-- Sidebar -->
        <Sidebar v-if="$auth.isLoggedIn && this.userDetails && termsAndPrivacyAccepted" :user-details="this.userDetails" :roleView="this.roleChangedTo"
          @show-modal="showGuestNavigationErrorModal = true" @show-role-switch-confirmation-modal= "showRoleSwitchConfirmationModal=true"
          class="hidden md:flex h-[7%] md:sticky md:top-[8.5%] print:hidden" />


        <!--Phone bottom navigation bar for Guest Facilitator, Educator, Admins  -->
        <template v-if="this.roleChangedTo=='Guest Facilitator'|| this.roleChangedTo=='Educator' ||  this.roleChangedTo=='System Manager'">
          <guestPhoneBottomNavbar v-if="$auth.isLoggedIn && this.userDetails && termsAndPrivacyAccepted"
            :user-details="this.userDetails" @show-modal="showGuestNavigationErrorModal = true"
            class="md:hidden h-[7%] md:sticky md:top-[8.5%] print:hidden" :class="['transition-all duration-400 linear',
              navbarHidden ? 'transform -translate-y-[-80px] z-40' : '']"></guestPhoneBottomNavbar>
        </template>
        
        <template v-if="this.roleChangedTo=='Parent'">
          <!-- Phone bottom navigation bar for Parent-->
          <phoneBottomNavbar v-if="$auth.isLoggedIn && this.userDetails && termsAndPrivacyAccepted"
            :user-details="this.userDetails" @show-modal="showGuestNavigationErrorModal = true"
            class="md:hidden h-[7%] md:sticky md:top-[8.5%] print:hidden" :class="['transition-all duration-400 linear',
              navbarHidden ? 'transform -translate-y-[-80px] z-40' : '']"></phoneBottomNavbar>
        </template>

        <main class="flex-grow print:h-max" :class="[{ 'relative h-[87%] md:h-[91.3%] overflow-hidden md:w-[80vw] md:overflow-visible': $auth.isLoggedIn && termsAndPrivacyAccepted },
        navbarHidden ? 'h-[97.5%]' : 'transition-all duration-400 linear']">


          <!-- Upload status -->
          <UploadProgress
            v-if="uploadDetails.visible && uploadDetails.progress"
            :upload-progress="uploadDetails.progress"/>
          <UploadStatus v-if="uploadDetails.visible && uploadDetails.details.type==='success'" :total-files="uploadDetails.details.totalFiles"/>
          <ServerErrorToast v-if="uploadDetails.visible && uploadDetails.details.type==='error'"></ServerErrorToast>

          <!-- All the app pages/routes -->
          <router-view :options-response="optionsResponse" :toggleUploadStatus="toggleUploadStatus"
            :user-details="this.userDetails" :user-role-view="this.roleChangedTo"></router-view>
        </main>
      </div>
    </div>

    <!--Modal when Guest facilitator tries to access Menu options  -->
    <template v-if="showGuestNavigationErrorModal">
      <navigationErrorModal @close="showGuestNavigationErrorModal = false" :user-roles="this.userDetails.user_roles" @switch-role="handleRoleChange"></navigationErrorModal>
    </template>

    <!-- Modal for Confirming user want to switch role and Handling role -->
    <template v-if="showRoleSwitchConfirmationModal">
      <roleSwitchConfirmationModal v-if="this.roleChangedTo" :current-role="this.roleChangedTo" :user-roles="this.userDetails.user_roles" @switch-role="handleRoleChange" @close="showRoleSwitchConfirmationModal = false"></roleSwitchConfirmationModal>
    </template>

    <!-- Modal for 'How to install zippie' popup -->
    <template v-if="showInstallZippieModal">
      <InstallZippieModal @close="showInstallZippieModal=false"></InstallZippieModal>
    </template>
    <ObservationModalWrapper />
  </div>
</template>


<script>
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue';
import UploadStatus from './components/photoEssays/UploadStatus.vue';
import UploadProgress from './components/photoEssays/UploadProgress.vue';
import ServerErrorToast from './components/photoEssays/ServerErrorToast.vue'
import navigationErrorModal from './components/guestFacilitator/navigationErrorModal.vue';
import phoneMenu from './components/navigationMenu/phoneMenu.vue';
import phoneBottomNavbar from './components/navigationMenu/phoneBottomNavbar.vue';

import 'preline/preline';
import { onMounted, computed } from 'vue';


import guestFacilitatorMenu from './components/navigationMenu/guestFacilitatorMenu.vue';
import GuestFacilitatorMenu from './components/navigationMenu/guestFacilitatorMenu.vue';
import roleSwitchConfirmationModal from './components/navigationMenu/roleSwitchConfirmationModal.vue';
import PhoneBottomNavbar from './components/navigationMenu/phoneBottomNavbar.vue';
import guestPhoneBottomNavbar from './components/navigationMenu/guestPhoneBottomNavbar.vue';
import InstallZippieModal from './components/install_zippie/InstallZippieModal.vue';
import ObservationModalWrapper from './components/observations/view/ObservationModalWrapper.vue';



export default {
  beforeUnmount() {
    console.log('Component is about to be unmounted');
  },
  components: {
    Navbar,
    Sidebar,
    UploadStatus,
    UploadProgress,
    ServerErrorToast,
    navigationErrorModal,
    phoneBottomNavbar,
    phoneMenu,
    guestFacilitatorMenu,
    roleSwitchConfirmationModal,
    guestPhoneBottomNavbar,
    InstallZippieModal,
    ObservationModalWrapper
  },
  inject: {
    $call: '$call', // Injecting the $call property
    $auth: '$auth', // Injecting the $auth property
  },
  provide() {
    return {
      userDetails: computed(() => this.userDetails),
      formOptionsData: computed(() => this.optionsResponse)
    }
  },

  data() {
    return {
      optionsResponse: null, // initially null
      userDetails: null,
      showUploadStatus: false,
      uploadDetails: {
        visible: false,
        details: {
          data: null,  // Ensure there is a nested structure for data
          error: null  // and error to avoid undefined issues
        },
        progress: 0,  // It might be better to start with 0
        termsAndPrivacyAccepted: false, // To only render the routes when user has accepted terms of dervices and privacy policy 
      },
      showGuestNavigationErrorModal: false,
      navbarHidden: false,

      showRoleSwitchConfirmationModal: false,
      roleChangedTo: localStorage.getItem('roleChangedTo') || '', //Too keep track of User Role view across the app for Multiple role users
      showInstallZippieModal: false //To  display modal for steps to install zippie PWA, toggled from event emmitied by navbar 
    };
  },

  methods: {
    async fetchFormOptionsData() {
      try {
        console.log("Fetch options data called in App.vue")
        // call to the 'fetch_data' function on the server side
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.fetch_options_data');
        console.log("Fetch options data Response", response)
        this.optionsResponse = response.data; // store full response object

      } catch (error) {
        console.error('Error fetching options data:', error);
      }
    },
    async fetchUserDetails() {
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.common.user_details.api.get_user_details')
        if (response.status === 'success') {
          this.userDetails = response.user_details
          
          // Only set the role if it's not already set in localStorage
          if (!this.roleChangedTo) {
            console.log("M: rolechangedTo is empty, assiging priority role from roles list")
            if (response.user_details.user_roles.includes('Guest Facilitator')) {
              this.saveRoleToLocalStorage('Guest Facilitator');
            } else if (response.user_details.is_system_manager) {
              this.saveRoleToLocalStorage('System Manager');
            } else if (response.user_details.user_roles.includes('Educator') && !response.user_details.is_system_manager) {
              this.saveRoleToLocalStorage('Educator');
            } else if (response.user_details.user_roles.includes('Parent') && response.user_details.user_roles.length <= 1) {
              this.saveRoleToLocalStorage('Parent');
            }
          }
          else if (this.roleChangedTo){
            // Confirm if the user has parent role
            if (this.roleChangedTo === 'Parent' && !response.user_details.user_roles.includes('Parent')){
              // Handle if a Non parent user selected 'I am a parent' option
              if (response.user_details.user_roles.includes('Guest Facilitator')) {
                  this.saveRoleToLocalStorage('Guest Facilitator');
                } else if (response.user_details.is_system_manager) {
                  this.saveRoleToLocalStorage('System Manager');
                } else if (response.user_details.user_roles.includes('Educator') && !response.user_details.is_system_manager) {
                  this.saveRoleToLocalStorage('Educator');
                } else if (response.user_details.user_roles.includes('Parent') && response.user_details.user_roles.length <= 1) {
                  this.saveRoleToLocalStorage('Parent');
                }
            }
          }
        
        }
        console.log("User Details fetched in App.vue", response)
      } catch (error) {
        console.error('Error fetching User details:', error)
      }
    
    },
    saveRoleToLocalStorage(role) {
      localStorage.setItem('roleChangedTo', role);
      this.roleChangedTo = role;
      console.log("N:This is the role saved in local storage:",role)
    },
    async fetchUserAgreementAcceptance() {
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.user_agreement_acceptance.api.check_user_agreement_status_from_app')

        if (response.status === 'success') {
          this.termsAndPrivacyAccepted = response.terms_and_privacy_accepted
        }
      } catch (error) {
        console.error('Error fetching User Agreement details:', error)
      }

    },
    handleRoleChange(newRole){
      console.log("handle role changed main function called")
      console.log("This is the role changed to =====>",newRole)
      this.saveRoleToLocalStorage(newRole);
      this.showRoleSwitchConfirmationModal = false
      if (newRole === "Guest Facilitator") {
        this.$router.push('/front-portal/observations').catch(err => {
          console.error('Navigation failed:', err);
          // Handle the error (e.g., show a notification to the user)
        });
      }
      if (newRole === "Parent") {
        this.$router.push('/front-portal/parent-dashboard').catch(err => {
          console.error('Navigation failed:', err);
        });
      }
      else {
        this.$router.push('/front-portal/observations').catch(err => {
          console.error('Navigation failed:', err);
        });
      }
    },
    toggleUploadStatus(status) {

      console.log("This is the upload status recieved in app", status)
      this.uploadDetails.details = status.details;
      this.uploadDetails.visible = status.visible;
      this.uploadDetails.progress = status.progress;

      console.log("This is the value of uploadDetails after assigning", this.uploadDetails)
    },
    showNavigationMenu() {
      console.log("Show Menu recieved in APP")
    },
    toggleNavbar() {
      // this.navbarHidden = !this.navbarHidden;
    }
  },
  mounted() {
    if (this.$auth.isLoggedIn) {
      this.fetchFormOptionsData();
      this.fetchUserDetails();
      this.fetchUserAgreementAcceptance();
    }
  },
  watch: {
    '$auth.isLoggedIn'(newVal) {
      if (newVal) {
        this.fetchFormOptionsData();
        console.log("Loging state changed, fetFormOptionData called inside Watcher")
        this.fetchUserDetails();
      }
      if (newVal== false) {
        // To clear role view when user logs out
        localStorage.removeItem('roleChangedTo');
      }
    }
  },
};
</script>

<style>
html,
body,
#app,
.container-div,
.wrapper {
  height: 100%;
}
</style>