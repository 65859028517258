<template>
  <ObservEditModal modalName="daily-observ-edit-modal-app" :version="selectedObservationVersion"
    :observation="selectedObservation" :selectedSignal="selectedSignal" :scrollSignal="scrollSignal" />
  <ActivityObservEditModal modalName="activity-observ-edit-modal-app" :observation="selectedObservation"
    :selectedSignal="selectedSignal" :version="selectedObservationVersion" :scrollSignal="scrollSignal" />
  <ActivityObservV3EditModal  modalName="activity-observ-v3-edit-modal-app" :observation="selectedObservation"
    :selectedSignal="selectedSignal" :version="selectedObservationVersion" :scrollSignal="scrollSignal" />
  <UnstructEditModal modalName="unstruct-edit-modal-app" :observation="selectedObservation"
    :selectedSignal="selectedSignal" :scrollSignal="scrollSignal" />
  <ParentObservEditModal modalName="parent-observ-edit-modal-app" :version="selectedObservationVersion"
    :observation="selectedObservation" :selectedSignal="selectedSignal" />
  <WeeklyChildEditModal modalName="weekly-child-edit-modal-app" :version="1"
    :observation="selectedObservation" :selectedSignal="selectedSignal" />
  <DailyObservationModal v-if="childModalActive" modalName="daily-form-modal-app" 
    :reloadFn="reloadChildModal" confirmModalName="observations" :student="selectedChild" />
  <FormConfirmModal :createAnotherFn="addAnotherFn" modalName="observations-app" 
    :formType="currentObservType" />
</template>

<script setup>
import ObservEditModal from '@/components/observations/edit/ObservEditModal.vue';
import UnstructEditModal from '@/components/observations/edit/Unstructured/UnstructEditModal.vue';
import ActivityObservEditModal from '@/components/observations/edit/Activity/ActivityObservEditModal.vue'
import ActivityObservV3EditModal from '@/components/observations/edit/Activity/ActivityObservV3EditModal.vue'
import ParentObservEditModal from '@/components/observations/edit/Parent/ParentObservEditModal.vue';
import WeeklyChildEditModal from '@/components/observations/edit/WeeklyChild/WeeklyChildEditModal.vue';
import { provide, readonly, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { createResource, createListResource } from 'frappe-ui';
import { HSOverlay } from 'preline';
import { useObservationModals,openObservationForm } from '../../../lib/observations';
import DailyObservationModal from '../forms/DailyObservationModal.vue';
import FormConfirmModal from '../forms/FormConfirmModal.vue';

const selectedObservationVersion = ref(1)
const selectedObservation = ref(null)

const scrollSignal =ref(true)
const selectedSignal = ref(true)
const loadedObservation = ref(false)

const route = useRoute()
const router = useRouter()
  console.log("Route changed(observations)", {route})

const competencies = ref([])
provide('observationCompetencies', readonly(competencies))

const competencyResource = createListResource({
  doctype: 'Competency',
  fields: ['name', 'competency_name', 'description'],
  orderBy: 'competency_name asc',
  pageLength: 60,
  auto: true,
})

watch(() => competencyResource.data, () => {
  if (competencyResource.data)
    competencies.value = competencyResource.data
})

const modalMapping = {
  daily: "daily-observ-edit-modal-app",
  activity: "activity-observ-edit-modal-app",
  activity3: "activity-observ-v3-edit-modal-app",
  unstructured: "unstruct-edit-modal-app",
  parent: "parent-observ-edit-modal-app",
  weeklychild: "weekly-child-edit-modal-app",
  // for creation below
  individual: 'daily-form-modal-app',
}

const getModalName = (observType, version) => {
  if (observType === 'activity' && version === 3) {
    return modalMapping.activity3;
  } else if (observType === 'activity') {
    return modalMapping.activity;
  } else if (observType === 'weekly-child') {
    return modalMapping.weeklychild;
  }
  return modalMapping[observType];
}

const queryObservationResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.api.get_observation',
})

const openQueryObserv = async (type, version, id) => {
  const modalName = getModalName(type, version);
  selectedObservation.value = null;
  selectedSignal.value = !selectedSignal;
  selectedObservationVersion.value = version;
  console.log(`opening modal: #hs-${modalName}`)
  HSOverlay.open(`#hs-${modalName}`)

  try {
    await queryObservationResource.submit({
      type: type,
      version: version,
      name: id,
    })
    console.log(queryObservationResource)
    if (queryObservationResource?.data?.status === 'success') {
      selectedObservation.value = queryObservationResource?.data?.observation;

      const scrollToComments = route.query.scroll
      // console.log(`Sscroll value is: ${scrollToComments}, switching signall`)
      if (scrollToComments == 1)
        scrollSignal.value = !scrollSignal.value
    } else {
      console.error("Failed to fetch observation")
      console.error(queryObservationResource?.data?.message)
      HSOverlay.close(`#hs-${modalName}`)
      // wait for overlay to close before changing route
      await new Promise(resolve => setTimeout(resolve, 300))
      router.push({
        path: route.path,
        query: {
          ...Object.fromEntries(Object.entries(route.query).filter(([key]) => !['observType', 'observVersion', 'observName'].includes(key)))
        }
      })
    }
  } catch (error) {
    console.error("Failed to make observation request")
    HSOverlay.close(`#hs-${modalName}`)
    // wait for overlay to close before changing route
    await new Promise(resolve => setTimeout(resolve, 300))
    router.push({
      path: route.path,
      query: {
        ...Object.fromEntries(Object.entries(route.query).filter(([key]) => !['observType', 'observVersion', 'observName'].includes(key)))
      }
    })
  }
  loadedObservation.value = true;
}

watch(route, (newRoute) => {
  loadedObservation.value = false;
  console.log("Route changed(observations)", {newRoute})
  const observationType = newRoute.query.observType;
  const observationVersion = isNaN(newRoute.query.observVersion) ? 1 : Number(newRoute.query.observVersion);
  const observationId = newRoute.query.observName;
  if (!observationId || !observationType)
    return

  const correctedType = observationType === 'child' ? 'daily' : observationType;
  console.log({correctedType, observationVersion, observationId})
  openQueryObserv(correctedType, observationVersion, observationId)
})

const childModalActive = ref(true)
const reloadChildModal = () => {
  console.log('Reloading Child observation modal')
  childModalActive.value = false
  setTimeout(() => childModalActive.value = true, 100)
}

const { 
  selectedChild, currentModalName, currentObservType 
} = useObservationModals();

const addAnotherFn = () => {
  HSOverlay.open(`#hs-${currentModalName.value}`)
}
</script>